

















































import { Component, PropSync, Vue } from 'vue-property-decorator';
import AllianzWithdrawalResultViewModel
  from '@/vue-app/view-models/allianz-dashboard/withdrawals/allianz-withdrawal-result-view-model';

@Component({ name: 'AllianzWithdrawalResult' })
export default class AllianzWithdrawalResult extends Vue {
  @PropSync('accreditationDate', { type: String, required: true })
  synced_accreditation_date!: string;

  withdrawal_result_view_model = Vue.observable(
    new AllianzWithdrawalResultViewModel(this),
  );
}

